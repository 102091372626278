<template>
  <component :is="dynamicComponent" v-if="dynamicComponent" v-bind="headerProps" />
</template>

<script>
import gql from 'graphql-tag';
import ImageOverlayText, {
  HeaderBlockImageOverlayTextFragment,
} from '@/components/common/headerBlock/ImageOverlayText';
import Heading, { HeaderBlockHeadingFragment } from '@/components/common/headerBlock/Heading';
import BoxHeading, { HeaderBlockBoxHeadingFragment } from '@/components/common/blocks/CourseConversionBanner';

export const HeaderBlockFragment = gql`
  fragment HeaderBlockFragment on headerBlock_MatrixField {
    ...HeaderBlockHeadingFragment
    ...HeaderBlockImageOverlayTextFragment
    ...HeaderBlockBoxHeadingFragment
  }
  ${HeaderBlockImageOverlayTextFragment}
  ${HeaderBlockHeadingFragment}
  ${HeaderBlockBoxHeadingFragment}
`;
/**
 * exports header query including all Headers
 * renders Header definded by cms-repsonse or empty header if not set
 */
export default {
  components: { ImageOverlayText, Heading, BoxHeading },
  props: {
    block: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
      default: null,
    },
    compareAtPrice: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dynamicComponent: null,
    };
  },
  computed: {
    headerProps() {
      return this.block[0]
        ? { ...this.block[0], staticPrice: this.price, staticCompareAtPrice: this.compareAtPrice }
        : null;
    },
  },
  created() {
    this.setDynamicComponent();
  },
  methods: {
    setDynamicComponent() {
      switch (this.headerProps?.typeHandle) {
        case 'imageOverlayText':
          this.dynamicComponent = ImageOverlayText;
          break;
        case 'boxHeading':
          this.dynamicComponent = BoxHeading;
          break;
        case 'heading':
          this.dynamicComponent = Heading;
          break;
        default:
          console.warn('no header defined for Page');
      }
    },
  },
};
</script>
