<template>
  <div class="ImageOverlayText relative h-full">
    <CldnImg
      v-if="image[0]"
      :src="image[0].url"
      :alt="image[0].title"
      crop="fill"
      sizes="100vw"
      class="object-cover w-full"
      :class="smallHeader ? 'background-image-small' : 'background-image-large'"
    />
    <div class="absolute inset-0 overflow-hidden">
      <div class="container h-full">
        <div class="lg:items-start py-l relative flex items-center h-full">
          <div>
            <BarText v-if="heading" :text="heading" tag="h1" class="fluid-5xl leading-none" />
            <LinkField
              v-if="cta"
              v-bind="cta"
              class="mt-10"
              :class="buttonWhite ? 'btn-pill-outline-white' : 'btn-pill-outline-black'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import BarText from '@/components/BarText';
import CldnImg from '@/components/CldnImg';
import LinkField from '@/components/common/LinkField';

export const HeaderBlockImageOverlayTextFragment = gql`
  fragment HeaderBlockImageOverlayTextFragment on headerBlock_imageOverlayText_BlockType {
    typeHandle
    heading
    image {
      url
      title
    }
    cta {
      ...LinkFragment
    }
    buttonWhite
    smallHeader
  }
`;

export default {
  components: {
    BarText,
    CldnImg,
    LinkField,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
    cta: {
      type: Object,
      default: null,
    },
    buttonWhite: {
      type: Boolean,
      default: false,
    },
    smallHeader: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.background-image-large {
  height: 120vw;
}
@screen lg {
  .background-image-large {
    height: 80vh;
    min-height: 400px;
  }
}
.background-image-small {
  height: 80vw;
}
@screen lg {
  .background-image-small {
    height: 60vh;
    min-height: 230px;
  }
}
</style>
