<template>
  <div>
    <HeaderBlock :block="entry.headerBlock" />
    <ShopBlocks :blocks="entry.shopBlocks" />
  </div>
</template>
<script>
import gql from 'graphql-tag';
import ShopBlocks, { ShopBlocksMatrixFragment } from '@/components/shop/ShopBlocks';
import HeaderBlock, { HeaderBlockFragment } from '@/components/common/headerBlock/HeaderBlock';

export const ShopPageFragment = gql`
  fragment ShopPageFragment on shop_shopPage_Entry {
    title
    uri
    headerBlock {
      ...HeaderBlockFragment
    }
    shopBlocks {
      ...ShopBlocksMatrixFragment
    }
  }

  ${ShopBlocksMatrixFragment}
  ${HeaderBlockFragment}
`;

export default {
  name: 'ShopPage',
  components: { ShopBlocks, HeaderBlock },
  props: {
    entry: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
