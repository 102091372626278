<template>
  <ShopPage :entry="entry" />
</template>
<script>
import gql from 'graphql-tag'
import entryPage, { SeomaticFragment, EntryPageFragment } from '@/mixins/entryPage'
import ShopPage, { ShopPageFragment } from '@/components/shop/ShopPage'
import { stripLocale } from '@/utils/i18n'

export const ShopPageQuery = gql`
  query ShopPageQuery($site: String!, $uri: String!) {
    entry(section: "shop", site: [$site], uri: [$uri]) {
      ...ShopPageFragment
      ...EntryPageFragment
    }

    seomatic(site: $site, uri: $uri, asArray: true) {
      ...SeomaticFragment
    }
  }
  ${EntryPageFragment}
  ${ShopPageFragment}
  ${SeomaticFragment}
`

export default {
  name: 'ShopPageController',
  components: { ShopPage },
  mixins: [entryPage],

  async asyncData({ $craftRequest, $retourRedirect, app, params, error, route }) {
    const { locale } = app.i18n
    const uri = stripLocale(route.path, locale)
    const variables = { site: locale, uri }
    const { entry, seomatic } = await $craftRequest(ShopPageQuery, variables)
    if (entry === null) {
      return $retourRedirect()
    }
    return { entry, seomatic }
  },
  mounted() {
    this.setBreadCrumb()
    this.gtmPushPageMeta({ content_category: 'shop' })
  }
}
</script>
