<template>
  <div class="bg-blackly py-l text-center">
    <h1 class="text-white fluid-4xl leading-none container sm:w-4/5" v-html="heading"></h1>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export const HeaderBlockHeadingFragment = gql`
  fragment HeaderBlockHeadingFragment on headerBlock_heading_BlockType {
    typeHandle
    heading
  }
`

export default {
  name: 'Heading',
  props: {
    heading: {
      type: String,
      default: null
    }
  }
}
</script>
